
import { ref } from 'vue'
import { db } from '@/main'
import 'firebase/storage'
import firebase from 'firebase/app'

export default {
  setup () {
    const Photos = ref([])

    db.collection('photos').onSnapshot((snapshotChange) => {
      Photos.value = []
      snapshotChange.forEach((doc) => {
        Photos.value.push({
          key: doc.id,
          name: doc.data().name,
          description: doc.data().description,
          arrayImages: doc.data().arrayImages,
          NameImages: doc.data().NameImages
        })
      })
    })

    const deletePhoto = (photo) => {
      if (window.confirm('Ви дійсно хочете видалити?')) {
        const File = photo.arrayImages

        if (File) {
          for (let i = 0; i < File.length; i++) {
            const storageRef = firebase.storage().ref()
            const nameTime = photo.NameImages[i]
            const Ref = storageRef.child(`${photo.name}/` + nameTime)

            Ref.delete()
              .then(() => {
                console.log('удаление фото со всем объявлением')
              })
              .catch((error) => {
                console.log(error)
              })
          }
        }
        db.collection('photos')
          .doc(photo.key)
          .delete()
          .then(() => {
            console.log('Документ видалено!')
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
    return {
      Photos,
      deletePhoto
    }
  }
}
